<mat-sidenav-container class="h-full w-full">
  <mat-sidenav #sidenav mode="side" opened class="bg-zinc-900 text-white text-base h-full w-72">
    <mat-toolbar class="text-white bg-zinc-500">
      <mat-toolbar-row>
        <div class="content-start">
          <img class="w-9 h-9" src="assets/running_man.ico" />
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <navigation></navigation>
  </mat-sidenav>

  <mat-sidenav-content class="flex-auto h-screen bg-zinc-900 overflow-auto">
    <mat-toolbar class="text-white bg-zinc-500">
      <mat-toolbar-row>
        <button mat-icon-button (click)="sidenav.toggle()"><i class="fa-solid fa-bars"></i></button>
        <span>Intellivision Archive</span>
        <span class="flex-auto"></span>
        <button mat-icon-button aria-label="sign-in" *ngIf="!authenticated" (click)="signIn()">
          <i class="fa-duotone fa-arrow-right-to-bracket"></i>
        </button>
        <button mat-icon-button aria-label="sign-out" *ngIf="authenticated" (click)="signOut()">
          <i class="fa-duotone fa-arrow-right-from-bracket"></i>
        </button>
        <span style="width:20px">&nbsp;</span>
      </mat-toolbar-row>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>