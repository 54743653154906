import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { ChangePasswordDialogComponent } from './dialogs/authentication/change-password-dialog/change-password-dialog.component';

import { SignInDialogComponent } from './dialogs/authentication/sign-in-dialog/sign-in-dialog.component';
import { AuthenticationService, ConsoleLogger, IntvService, LoggingService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private _logger: ConsoleLogger;
  title = '';
  authenticated = false;

  constructor(
    private router: Router,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService,
    private intvService: IntvService,
    public dialog: MatDialog
  ) {
    this._logger = loggingService.getLogger('App', 'debug');
  }

  ngOnInit() {
    this.title = environment.title;

    this.authenticationService.authenticated$.subscribe((authenticated) => {
      this.authenticated = authenticated;
    });
    this.authenticationService.refreshAccessToken();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // this._logger.debug('event.url', { url: event.url });
        // Could add more chars url:path?=;other possible
        const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
        // const currentUrlPath = event.url.slice(1).split(urlDelimitators)[0];
        const currentUrlPath = event.url.slice(1).split(urlDelimitators);
        // this._logger.debug('URL_PATH: ', currentUrlPath);
        this.intvService.currentRoute$.next(currentUrlPath);
      }
    });
  }

  signIn() {
    // this._logger.debug('signIn');
    let dialogRef = this.dialog.open(SignInDialogComponent, {
      data: { email: '', password: '' },
    });

    dialogRef.afterClosed().subscribe((signinResponse) => {
      // this._logger.debug('The dialog was closed', { result });
      this.authenticationService.signIn(signinResponse).then((authResult) => {
        this._logger.debug('authResult', authResult);
        if (authResult && authResult.ChallengeName) {
          dialogRef = this.dialog.open(ChangePasswordDialogComponent, {});
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this._logger.debug('ChangePassword', { authResult, result });
              if (authResult.Session && authResult.ChallengeName) {
                this.authenticationService.respondToAuthChallenge(
                  authResult.Session,
                  authResult.ChallengeName,
                  { USERNAME: result.email, NEW_PASSWORD: result.password }
                );
              }
            }
          });
        }
      });
    });
  }

  signOut() {
    this.authenticationService.signOut();
  }
}
