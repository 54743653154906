<h1 mat-dialog-title>Add Publisher</h1>
<div mat-dialog-content>

  <form [formGroup]="form">
    <div>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Description</mat-label>
        <input name="description" formControlName="description" matInput required />
      </mat-form-field>
    </div>

    <div>
      <div>
        <mat-checkbox name="hardware" formControlName="hardware">Hardware</mat-checkbox>
      </div>
      <div>
        <mat-checkbox name="software" formControlName="software">Software</mat-checkbox>
      </div>
      <div>
        <mat-checkbox name="miscellaneous" formControlName="miscellaneous">Miscellaneous</mat-checkbox>
      </div>
    </div>
  </form>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="save()">Ok</button>
</div>