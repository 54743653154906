import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Publisher } from 'src/app/models';
import { IntvService } from 'src/app/services';

@Component({
  selector: 'app-add-publisher',
  templateUrl: './add-publisher.component.html',
  styleUrls: ['./add-publisher.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddPublisherComponent implements OnDestroy {
  private subscriptions: { [key: string]: Subscription } = {};

  form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddPublisherComponent>,
    private intvService: IntvService,
    @Inject(MAT_DIALOG_DATA) public data: Publisher
  ) {
    this.createForm();
  }

  ngOnDestroy(): void {
    // this._logger.debug('ngOnDestroy');
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.unsubscribe();
    }
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      description: ['', [Validators.required]],
      hardware: [false],
      software: [false],
      miscellaneous: [false],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.form.valid) {
      this.subscriptions['add'] = this.intvService
        .addPublisher(this.form.value)
        .subscribe((res) => {
          const newPublisher = res;
          this.intvService.loadPublishers();

          this.dialogRef.close(newPublisher);
        });
    }
  }
}
