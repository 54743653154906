<div class="w-full h-full justify-center block">
  <button mat-icon-button aria-label="box" class="p-0" *ngIf="cellValue.component" matTooltip="Component">
    <i class="fa-duotone fa-microchip text-white"></i>
  </button>
  <button mat-icon-button aria-label="box" class="p-0" *ngIf="!cellValue.component" disabled></button>

  <button mat-icon-button aria-label="box" class="p-0" *ngIf="cellValue.box" matTooltip="Box">
    <i class="fa-duotone fa-box text-white"></i>
  </button>
  <button mat-icon-button aria-label="box" class="p-0" *ngIf="!cellValue.box" disabled></button>

  <button mat-icon-button aria-label="manual" class="p-0" *ngIf="cellValue.manual" matTooltip="Manual">
    <i class="fa-duotone fa-file-pdf text-white"></i>
  </button>
  <button mat-icon-button aria-label="manual" class="p-0" *ngIf="!cellValue.manual" disabled>
  </button>

  <button mat-icon-button aria-label="rom" class="p-0" *ngIf="cellValue.rom" matTooltip="ROM">
    <i class="fa-duotone fa-memory text-white"></i>
  </button>
  <button mat-icon-button aria-label="rom" class="p-0" *ngIf="!cellValue.rom" disabled>
  </button>

  <span class="p-0 text-white text-sm"
    *ngIf="cellValue.overlays.total > 0">{{cellValue.overlays.current}}/{{cellValue.overlays.total}}</span>
</div>