import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of, Subscription } from 'rxjs';
import { startWith, debounceTime, switchMap, finalize, tap, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import {
  AuthenticationService,
  ConsoleLogger,
  IntvService,
  LoggingService,
} from 'src/app/services';
import { Software, Publisher, SearchOptions } from 'src/app/models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddPublisherComponent } from 'src/app/dialogs/add-publisher/add-publisher.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-software-editor',
  templateUrl: './software-editor.component.html',
  styleUrls: ['./software-editor.component.scss'],
})
export class SoftwareEditorComponent implements OnInit, OnDestroy {
  private _logger: ConsoleLogger;

  private subscriptions: { [key: string]: Subscription } = {};

  form!: FormGroup;
  title = 'Add Software';
  isLoading = false;
  item: Software = {} as Software;
  publishers: Publisher[] = [];
  filteredPublishers!: Observable<Publisher[]>;
  filteredSoftware: Software[] = [];

  dialogRef!: MatDialogRef<AddPublisherComponent>;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private loggingService: LoggingService,
    private intvService: IntvService
  ) {
    this._logger = loggingService.getLogger('SoftwareEditor', environment.logLevel);
    this.createForm();
  }

  ngOnInit(): void {
    this.subscriptions['selectedItem'] = this.intvService.selectedItem$.subscribe((item) => {
      if (item) {
        this.item = item as Software;
        this.title =
          this.item.publisher && this.item.id
            ? `${this.item.publisher.description} ${this.item.description}`
            : `Add Software - ${this.item.publisher.description}`;
      }
    });

    this.subscriptions['publisher'] = this.intvService.publishers$.subscribe((data) => {
      this.publishers = data.filter((x) => x.id !== '0');
      this.patchForm();
    });
  }

  ngOnDestroy(): void {
    // this._logger.debug('ngOnDestroy');
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.unsubscribe();
    }
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      id: [],
      description: ['', [Validators.required]],
      modelNumber: [],
      publisher: [null, [Validators.required]],
      copyrightYear: [],
      releaseYear: [],
      players: ['1 - 1'],
      programmer: [],
      notes: [],
      historyLink: [],
      flags: this.formBuilder.group({
        original125: [false],
        homebrew: [false],
        released: [false],
        variant: [false],
        romOnly: [false],
        originalItem: [],
      }),
      textDescription: [],
      inventory: this.formBuilder.group({
        component: [false],
        box: [false],
        manual: [false],
        overlays: [],
        rom: [false],
      }),
      s3keys: this.formBuilder.group({
        box: [''],
        manual: [''],
        overlay: [''],
      }),
    });

    if (this.form) {
      const control = this.form.get('publisher');
      if (control) {
        this.filteredPublishers = control.valueChanges.pipe(
          startWith(''),
          map((value: string | Publisher) => {
            const description = typeof value === 'string' ? value : value.description;
            return description
              ? this._filterPublishers(description as string)
              : this.publishers.slice();
          })
        );
      }

      this.subscriptions['form'] = (this.form.controls['flags'] as FormGroup).controls[
        'originalItem'
      ].valueChanges
        .pipe(
          debounceTime(500),
          tap(() => {
            this.filteredSoftware = [];
            this.isLoading = true;
          }),
          switchMap((value: string | Software) => {
            if (value && typeof value === 'string' && value.trim() !== '') {
              return this.intvService
                .search({
                  key: 'SOFTWARE',
                  text: value,
                  flags: { original125: true },
                } as SearchOptions)
                .pipe(
                  finalize(() => {
                    this.isLoading = false;
                  })
                );
            } else {
              // if no value is present, return null
              return of([]);
            }
          })
        )
        .subscribe((data) => {
          if (data) {
            this.filteredSoftware = data.sort((a, b) => {
              if (a.publisher.description < b.publisher.description) {
                return -1;
              }
              if (a.publisher.description > b.publisher.description) {
                return 1;
              }
              if (a.description < b.description) {
                return -1;
              }
              if (a.description > b.description) {
                return 1;
              }
              return 0;
            }) as Software[];
          } else {
            this.filteredSoftware = [];
          }
        });
    }
  }

  // TODO: fix "players" and "overlays" to break up min/max and current/total to separate fields
  patchForm(): void {
    this._logger.debug('patchForm', { item: this.item });
    if (this.item) {
      this.form.patchValue({
        id: this.item.id,
        publisher: this.item.publisher,
        // this.publishers && this.item && this.item.publisher
        //   ? this.publishers.find((x) => x.id === this.item.publisher.id)
        //   : null,
        description: this.item.description,
        modelNumber: this.item.modelNumber,
        copyrightYear: this.item.copyrightYear,
        releaseYear: this.item.releaseYear,
        players: this.item.players ? `${this.item.players.min} - ${this.item.players.max}` : '',
        notes: this.item.notes,
        historyLink: this.item.historyLink,
        programmer: this.item.programmer,
        flags: {
          original125: this.item.original125,
          homebrew: this.item.homebrew,
          released: this.item.released,
          variant: this.item.variant,
          romOnly: this.item.romOnly,
          originalItem: this.item.originalItem,
        },
        textDescription: this.item.textDescription,
        inventory: {
          component: this.item.inventory.component ?? false,
          box: this.item.inventory.box ?? false,
          manual: this.item.inventory.manual ?? false,
          overlays: this.item.inventory.overlays
            ? `${this.item.inventory.overlays.current} - ${this.item.inventory.overlays.total}`
            : '0 - 0',
          rom: this.item.inventory.rom ?? false,
        },
        s3keys: {
          box: this.item.s3keys.box ?? '',
          manual: this.item.s3keys.manual ?? '',
          overlay: this.item.s3keys.overlay ?? '',
        },
      });
    }
  }

  getPublisherName(value: Publisher): string {
    return value ? value.description : '';
  }

  getSoftwareName(value: Software): string {
    return value && value.publisher ? `${value.publisher.description} - ${value.description}` : '';
  }

  isVariant(): boolean {
    if (this.form) {
      const ctrl = this.form.get('flags');
      if (ctrl) {
        const flags = ctrl.value;
        return flags.variant;
      }
    }
    return false;
  }

  addPublisher(): void {
    // console.log('addPublisher');
    this.dialogRef = this.matDialog.open(AddPublisherComponent, {
      data: {} as Publisher,
    });

    this.subscriptions['afterClosed'] = this.dialogRef
      .afterClosed()
      .subscribe((response: FormGroup) => {
        this._logger.debug('AddPublisher', { response });
        if (!response) {
          return;
        }

        this.form.patchValue({
          publisher: response,
        });
      });
  }

  discard(): void {
    this._logger.debug('discard', { route: this.intvService.lastNavigation$.value });
    this.router.navigate([this.intvService.lastNavigation$.value.route || 'home']);
  }

  save(): void {
    if (this.form.value.id) {
      // Clean up Inventory:
      const data = this.form.value;
      const overlays = data.inventory.overlays.split('-').map((x: string) => x.trim());
      data.inventory.overlays = {
        current: Number(overlays[0]),
        total: Number(overlays[1]),
      };

      // Clean up copyright/release years:
      data.copyrightYear = Number(data.copyrightYear);
      data.releaseYear = Number(data.releaseYear);

      const players = data.players.split('-').map((x: string) => x.trim());
      data.players = {
        min: Number(players[0]),
        max: Number(players[1]),
      };

      this.subscriptions['update'] = this.intvService
        .updateSoftwareItem(this.form.value.id, data)
        .subscribe({
          next: (res) => {
            if (res) {
              this.form.markAsPristine();
              // this.notificationService.show('Update successful');
              this.discard();
            }
          },
          error: (err) => {
            this._logger.error('save', { err });
            // this.notificationService.show('ERROR: ' + JSON.stringify(err), 'Ok', 0);
          },
        });
    } else {
      this.subscriptions['add'] = this.intvService
        .addSoftwareItem(this.form.value as Software)
        .subscribe((res) => {
          if (res) {
            // this.notificationService.show('Add successful');
            this.discard();
          }
        });
    }
  }

  updateOptions(event: MatCheckboxChange): void {
    if (event.source.name === 'original125') {
      this.form.patchValue({
        flags: {
          homebrew: false,
          released: true,
          variant: false,
        },
      });
    }
    if (['homebrew', 'released', 'variant'].includes(event.source.name ?? '')) {
      this.form.patchValue({
        flags: {
          original125: false,
        },
      });
    }
  }

  private _filterPublishers(value: string): Publisher[] {
    this._logger.debug('_filterPublishers', { value });
    const filterValue = value.toLowerCase();
    return this.publishers.filter((x) => x.description.toLowerCase().indexOf(filterValue) === 0);
  }
}
