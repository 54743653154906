<div class="w-full h-full justify-center block">
  <button mat-icon-button aria-label="box" class="p-0" *ngIf="cellValue.box" (click)="openLink(cellValue.box)"
    matTooltip="Box">
    <i class="fa-duotone fa-box text-white"></i>
  </button>
  <button mat-icon-button aria-label="box" class="p-0" *ngIf="!cellValue.box" disabled>
  </button>
  <button mat-icon-button aria-label="manual" class="p-0" *ngIf="cellValue.manual" (click)="openLink(cellValue.manual)"
    matTooltip="Manual">
    <i class="fa-duotone fa-file-pdf text-white"></i>
  </button>
  <button mat-icon-button aria-label="manual" class="p-0" *ngIf="!cellValue.manual" disabled>
  </button>
  <button mat-icon-button aria-label="overlay" class="p-0" *ngIf="cellValue.overlay"
    (click)="openLink(cellValue.overlay)" matTooltip="Overlay">
    <i class="fa-duotone fa-file-image text-white"></i>
  </button>
  <button mat-icon-button aria-label="overlay" class="p-0" *ngIf="!cellValue.overlay" disabled>
  </button>
</div>