import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription, of } from 'rxjs';
import { startWith, map, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import {
  AuthenticationService,
  ConsoleLogger,
  IntvService,
  LoggingService,
} from 'src/app/services';
import { Hardware, Publisher, SearchOptions } from 'src/app/models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddPublisherComponent } from 'src/app/dialogs/add-publisher/add-publisher.component';

@Component({
  selector: 'app-hardware-editor',
  templateUrl: './hardware-editor.component.html',
  styleUrls: ['./hardware-editor.component.scss'],
})
export class HardwareEditorComponent implements OnInit, OnDestroy {
  private _logger: ConsoleLogger;

  private subscriptions: { [key: string]: Subscription } = {};

  form!: FormGroup;
  title = 'Add Hardware';
  isLoading = false;
  item: Hardware = {} as Hardware;
  publishers: Publisher[] = [];
  filteredPublishers!: Observable<Publisher[]>;
  filteredHardware: Hardware[] = [];

  dialogRef!: MatDialogRef<AddPublisherComponent>;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private loggingService: LoggingService,
    private intvService: IntvService
  ) {
    this._logger = loggingService.getLogger('HardwareEditor', environment.logLevel);
    this.createForm();
  }

  ngOnInit(): void {
    this.subscriptions['selectedItem'] = this.intvService.selectedItem$.subscribe((item) => {
      if (item) {
        this.item = item as Hardware;
        this.title = this.item.publisher
          ? `${this.item.publisher.description} ${this.item.description}`
          : 'Add Hardware';
      }
    });

    this.subscriptions['publisher'] = this.intvService.publishers$.subscribe((data) => {
      this.publishers = data.filter((x) => x.id !== '0');
      this.patchForm();
    });
  }

  ngOnDestroy(): void {
    // this._logger.debug('ngOnDestroy');
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.unsubscribe();
    }
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      id: [],
      description: ['', [Validators.required]],
      modelNumber: [],
      publisher: [null, [Validators.required]],
      copyrightYear: [],
      releaseYear: [],
      notes: [],
      historyLink: [],
      flags: this.formBuilder.group({
        homebrew: [false],
        released: [false],
        variant: [false],
        originalItem: [],
      }),
      inventory: this.formBuilder.group({
        component: [false],
        box: [false],
        manual: [false],
      }),
    });

    if (this.form) {
      const control = this.form.get('publisher');
      if (control) {
        this.filteredPublishers = control.valueChanges.pipe(
          startWith(''),
          map((value: string | Publisher) => {
            const description = typeof value === 'string' ? value : value.description;
            return description
              ? this._filterPublishers(description as string)
              : this.publishers.slice();
          })
        );

        this.subscriptions['form'] = (this.form.controls['flags'] as FormGroup).controls[
          'originalItem'
        ].valueChanges
          .pipe(
            debounceTime(500),
            tap(() => {
              this.filteredHardware = [];
              this.isLoading = true;
            }),
            switchMap((value: string | Hardware) => {
              if (value && typeof value === 'string' && value.trim() !== '') {
                return this.intvService
                  .search({
                    key: 'HARDWARE',
                    text: value,
                    flags: { original125: true },
                  } as SearchOptions)
                  .pipe(
                    finalize(() => {
                      this.isLoading = false;
                    })
                  );
              } else {
                // if no value is present, return null
                return of([]);
              }
            })
          )
          .subscribe((data) => {
            if (data) {
              this.filteredHardware = data.sort((a, b) => {
                if (a.publisher.description < b.publisher.description) {
                  return -1;
                }
                if (a.publisher.description > b.publisher.description) {
                  return 1;
                }
                if (a.description < b.description) {
                  return -1;
                }
                if (a.description > b.description) {
                  return 1;
                }
                return 0;
              }) as Hardware[];
            } else {
              this.filteredHardware = [];
            }
          });
      }
    }
  }

  patchForm(): void {
    if (this.item) {
      this.form.patchValue({
        id: this.item.id,
        publisher:
          this.publishers && this.item && this.item.publisher
            ? this.publishers.find((x) => x.id === this.item.publisher.id)
            : null,
        description: this.item.description,
        modelNumber: this.item.modelNumber,
        copyrightYear: this.item.copyrightYear,
        releaseYear: this.item.releaseYear,
        notes: this.item.notes,
        historyLink: this.item.historyLink,
        flags: {
          released: this.item.releaseYear > 0,
        },
        inventory: {
          component: this.item.inventory.component,
          box: this.item.inventory.box,
          manual: this.item.inventory.manual,
          overlays: this.item.inventory.overlays
            ? `${this.item.inventory.overlays.current} - ${this.item.inventory.overlays.total}`
            : '0 - 0',
        },
      });
    }
  }

  getHardwareName(value: Hardware): string {
    return value && value.publisher ? `${value.publisher.description} - ${value.description}` : '';
  }

  getPublisherName(value: Publisher): string {
    return value ? value.description : '';
  }

  isVariant(): boolean {
    if (this.form) {
      const ctrl = this.form.get('flags');
      if (ctrl) {
        const flags = ctrl.value;
        return flags.variant;
      }
    }
    return false;
  }

  addPublisher(): void {
    // console.log('addPublisher');
    this.dialogRef = this.matDialog.open(AddPublisherComponent, {
      panelClass: 'publisher-form-dialog',
    });

    this.subscriptions['afterClosed'] = this.dialogRef
      .afterClosed()
      .subscribe((response: FormGroup) => {
        // console.log(response);
        if (!response) {
          return;
        }

        this.form.patchValue({
          publisher: response,
        });
      });
  }

  discard(): void {
    this.router.navigate([this.intvService.lastNavigation$.value || 'home']);
  }

  save(): void {
    if (this.form.value.id) {
      // Clean up Inventory:
      const data = this.form.value;

      this.subscriptions['update'] = this.intvService
        .updateHardwareItem(this.form.value.id, data)
        .subscribe({
          next: (res) => {
            if (res) {
              this.form.markAsPristine();
              // this.notificationService.show('Update successful');
              this.discard();
            }
          },
          error: (err) => {
            this._logger.error('save', { err });
            // this.notificationService.show('ERROR: ' + JSON.stringify(err), 'Ok', 0);
          },
        });
    } else {
      this.subscriptions['add'] = this.intvService
        .addHardwareItem(this.form.value)
        .subscribe((res) => {
          if (res) {
            // this.notificationService.show('Add successful');
            this.discard();
          }
        });
    }
  }

  private _filterPublishers(value: string): Publisher[] {
    this._logger.debug('filterPublishers', { value });
    const filterValue = value.toLowerCase();

    return this.publishers.filter((x) => x.description.toLowerCase().indexOf(filterValue) === 0);
  }
}
