<div class="h-screen">
  <!-- HEADER -->
  <mat-toolbar class="text-white bg-zinc-700 h-11">
    <i class="fa-duotone fa-microchip pl-2"></i>
    <span class="text-white pl-2">
      {{ title }}
    </span>
  </mat-toolbar>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="h-full bg-zinc-300">
    <form [formGroup]="form" class="bg-zinc-300">
      <input type="hidden" formControlName="id" />
      <div class="grid grid-cols-4 grid-rows-8 gap-1 p-2">
        <div class="col-span-4">
          <mat-form-field class="w-96" appearance="outline">
            <mat-label>Publisher</mat-label>
            <input type="text" placeholder="Pick one" aria-label="Publisher" matInput formControlName="publisher"
              [matAutocomplete]="publisher" required />
            <mat-autocomplete autoActiveFirstOption #publisher="matAutocomplete" [displayWith]="getPublisherName">
              <mat-option *ngFor="let publisher of filteredPublishers | async" [value]="publisher">
                {{ publisher.description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <button mat-button mat-raised-button matTooltipPosition="above" matTooltip="New Publisher" class="ml-2"
            aria-label="Add" (click)="addPublisher()">
            <i class="fa-duotone fa-plus"></i>
          </button>
        </div>

        <div class="col-span-2">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Description</mat-label>
            <input matInput name="description" formControlName="description" required />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Model Number</mat-label>
            <input matInput name="modelNumber" formControlName="modelNumber" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Copyright Year</mat-label>
            <input matInput name="copyrightYear" formControlName="copyrightYear" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Release Year</mat-label>
            <input matInput name="releaseYear" formControlName="releaseYear" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Players</mat-label>
            <input matInput name="players" formControlName="players" type="text" [dropSpecialCharacters]="false"
              mask="0 - 0" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Programmer</mat-label>
            <input matInput name="programmer" formControlName="programmer" />
          </mat-form-field>
        </div>

        <div class="col-span-2">
          <mat-form-field appearance="outline" class="w-full h-full">
            <mat-label>Notes</mat-label>
            <input matInput name="notes" formControlName="notes" />
          </mat-form-field>
        </div>

        <div class="col-span-2">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>History Link</mat-label>
            <input matInput name="historyLink" formControlName="historyLink" />
          </mat-form-field>
        </div>

        <div class="col-span-4 row-span-4">
          <mat-form-field appearance="outline" class="w-full h-full">
            <mat-label>Text Description</mat-label>
            <textarea matInput cdkTextareaAutosize name="textDescription" formControlName="textDescription"> </textarea>
          </mat-form-field>
        </div>

        <div formGroupName="flags" class="border-solid border-zinc-700 border-2 rounded">
          <div class="p-2">
            <div class="text-base text-black">Flags</div>
            <div>
              <mat-checkbox name="original125" formControlName="original125" (change)="updateOptions($event)">Original
                125
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox name="homebrew" formControlName="homebrew" (change)="updateOptions($event)">Homebrew
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox name="released" formControlName="released" (change)="updateOptions($event)">Released
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox name="variant" formControlName="variant"
                (change)="updateOptions($event)">Variant</mat-checkbox>
            </div>
            <div>
              <mat-checkbox name="romOnly" formControlName="romOnly" (change)="updateOptions($event)">ROM Only
              </mat-checkbox>
            </div>
          </div>

          <div *ngIf="isVariant()" class="col-span-4">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Original Item</mat-label>
              <input matInput placeholder="Search" aria-label="Original Item" [matAutocomplete]="originalItem"
                formControlName="originalItem" />
              <mat-autocomplete #originalItem="matAutocomplete" [displayWith]="getSoftwareName">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let item of filteredSoftware" [value]="item">
                    <span>{{ item.publisher.description }} - {{ item.description }} {{ item.notes ? '(' + item.notes +
                      ')' : ''}}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div formGroupName="inventory" class="border-solid border-zinc-700 border-2 rounded">
          <div class="p-2">
            <div class="text-base text-black">Inventory</div>
            <div>
              <mat-checkbox name="component" formControlName="component">Component</mat-checkbox>
            </div>
            <div>
              <mat-checkbox name="manual" formControlName="manual">Manual</mat-checkbox>
            </div>
            <div>
              <mat-checkbox name="box" formControlName="box">Box</mat-checkbox>
            </div>
            <div><mat-checkbox name="rom" formControlName="rom">ROM</mat-checkbox></div>
            <div>
              <mat-form-field class="field-xs" appearance="outline" fxLayout="row">
                <mat-label>Overlays</mat-label>
                <input matInput name="overlays" formControlName="overlays" type="text" [dropSpecialCharacters]="false"
                  mask="0 - 0" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div formGroupName="s3keys" class="border-solid border-zinc-700 border-2 rounded">
          <div class="p-2">
            <div class="text-base text-black">S3 Keys</div>
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Box</mat-label>
              <input matInput name="box" formControlName="box" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Manual</mat-label>
              <input matInput name="manual" formControlName="manual" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Overlay</mat-label>
              <input matInput name="overlay" formControlName="overlay" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>

    <div class="p-2">
      <button mat-raised-button [disabled]="form.invalid || form.pristine" (click)="save()">
        SAVE
      </button>

      <button mat-raised-button class="ml-8" (click)="discard()">
        <span *ngIf="form.pristine">Back</span>
        <span *ngIf="!form.pristine">Discard</span>
      </button>
    </div>
  </div>
</div>