import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import {
  AuthenticationResultType,
  ChallengeNameType,
} from '@aws-sdk/client-cognito-identity-provider';

import { ConsoleLogger, LoggingService } from '../services/logging.service';
import { AuthenticatedUser, User } from '../models/authentication';
import { ConfigurationService } from './configuration.service';
import { environment } from 'src/environments/environment';

export interface AuthenticationResponse {
  Session?: string;
  ChallengeName?: ChallengeNameType | string;
  ChallengeParameters?: Record<string, string>;
  AuthenticationResult?: AuthenticationResultType;
  User?: Partial<User>;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _logger: ConsoleLogger;

  user$ = new BehaviorSubject<AuthenticatedUser>({} as AuthenticatedUser);
  authenticated$ = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private loggingService: LoggingService,
    private configurationService: ConfigurationService
  ) {
    this._logger = loggingService.getLogger('AuthenticationService', environment.logLevel);
  }

  signIn(credentials: { email: string; password: string }): Promise<AuthenticationResponse> {
    // this._logger.debug('signIn', { credentials });
    return new Promise((resolve) => {
      this.httpClient
        .post<AuthenticationResponse>(
          `${this.configurationService.api.baseURL}/authentication/sign-in`,
          credentials,
          {
            headers: {
              'x-api-key': this.configurationService.api.apiKey,
            },
          }
        )
        .subscribe((data) => {
          this._logger.debug('signIn', { response: data });
          if (data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            resolve(data);
          }
          if (data.AuthenticationResult) {
            this.authenticated$.next(true);
            this.setToken('accessToken', data.AuthenticationResult.AccessToken ?? '');
            this.setToken('refreshToken', data.AuthenticationResult.RefreshToken ?? '');
            this.setToken('idToken', data.AuthenticationResult.IdToken ?? '');
            this.user$.next(data.User as AuthenticatedUser);
          }
          resolve(data);
        });
    });
  }

  signOut(): void {
    this._logger.debug('signOut');
    this.removeToken('accessToken');
    this.removeToken('idToken');
    this.removeToken('refreshToken');
    this.authenticated$.next(false);
    this.user$.next({} as AuthenticatedUser);
    this.router.navigate(['home']);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<'SUCCESS'> {
    this._logger.debug('changePassword', { user: this.user$.value, oldPassword, newPassword });
    return this.httpClient.post<'SUCCESS'>(
      `${this.configurationService.api.baseURL}/authentication/sign-in`,
      {
        token: this.getToken('accessToken'),
        oldPassword,
        newPassword,
      },
      {
        headers: {
          'x-api-key': this.configurationService.api.apiKey,
        },
      }
    );
  }

  forgotPassword(username: string): Observable<boolean> {
    // return Auth.forgotPassword(username);
    this._logger.debug('forgotPassword', { username });
    return this.httpClient.post<boolean>(
      `${this.configurationService.api.baseURL}/authentication/forgot-password`,
      {
        username,
      },
      {
        headers: {
          'x-api-key': this.configurationService.api.apiKey,
        },
      }
    );
  }

  refreshAccessToken(): void {
    // this._logger.debug('refreshAccessToken');
    const token = this.getToken('refreshToken');
    // this._logger.debug('refreshAccessToken', { token });
    if (token) {
      this.httpClient
        .post<AuthenticationResponse>(
          `${this.configurationService.api.baseURL}/authentication/refresh-access-token`,
          { token },
          {
            headers: {
              'x-api-key': this.configurationService.api.apiKey,
            },
          }
        )
        .subscribe((data) => {
          // this._logger.debug('refreshAccessToken', { response: data });
          if (data.AuthenticationResult) {
            this.authenticated$.next(true);
            if (data.AuthenticationResult.AccessToken) {
              this.setToken('accessToken', data.AuthenticationResult.AccessToken);
            }
            if (data.AuthenticationResult.RefreshToken) {
              this.setToken('refreshToken', data.AuthenticationResult.RefreshToken);
            }
            if (data.AuthenticationResult.IdToken) {
              this.setToken('idToken', data.AuthenticationResult.IdToken);
            }
            this.user$.next(data.User as AuthenticatedUser);
          }
        });
    }
  }

  respondToAuthChallenge(
    sessionId: string,
    challengeName: string,
    challengeResponses: Record<string, string>
  ): Promise<AuthenticationResponse> {
    this._logger.debug('respondToAuthChallenge', {
      sessionId,
      challengeName,
      challengeResponses,
    });
    return new Promise((resolve) => {
      this.httpClient
        .post<AuthenticationResponse>(
          `${this.configurationService.api.baseURL}/authentication/respond-to-auth-challenge`,
          {
            sessionId,
            challengeName,
            challengeResponses,
          },
          {
            headers: {
              'x-api-key': this.configurationService.api.apiKey,
            },
          }
        )
        .subscribe((data) => {
          this._logger.debug('respondToAuthChallenge', { response: data });
          if (data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            resolve(data);
          }
          if (data.AuthenticationResult) {
            this.authenticated$.next(true);
            this.setToken('accessToken', data.AuthenticationResult.AccessToken ?? '');
            this.setToken('refreshToken', data.AuthenticationResult.RefreshToken ?? '');
            this.setToken('idToken', data.AuthenticationResult.IdToken ?? '');
            this.user$.next(data.User as AuthenticatedUser);
          }
          resolve(data);
        });
    });
  }

  public getToken(name: string): string | null {
    return localStorage.getItem(name);
  }

  removeToken(name: string): void {
    localStorage.removeItem(name);
  }

  setToken(name: string, value: string): void {
    localStorage.setItem(name, value);
  }
}
