import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

interface ImageObject {
  image?: string;
  thumbImage: string;
  alt?: string;
  title?: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  private httpClient: HttpClient;
  imageSources: ImageObject[] = [];
  private subscriptions: { [key: string]: Subscription } = {};

  options = {
    autoSlide: 5,
    imagePopup: false,
    infinite: false,
    manageImageRatio: true,
  };

  constructor(private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  ngOnInit(): void {
    this.load();
  }

  ngOnDestroy(): void {
    // this._logger.debug('ngOnDestroy');
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.unsubscribe();
    }
  }

  load() {
    const configUrl = '~/../assets/slideshow/_config.json';
    this.subscriptions['load'] = this.httpClient.get<string[]>(configUrl).subscribe((data) => {
      this.imageSources = data.map((x) => {
        return {
          thumbImage: `assets/slideshow/${x.replace(/[:]/gi, '')}`,
        };
      });
      this.options.infinite = true;
      this.options.autoSlide = 3;
    });
  }
}
