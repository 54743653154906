<h1 mat-dialog-title>Change Password</h1>
<div mat-dialog-content>
  <form name="changePasswordForm" [formGroup]="form">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="email" />
        <mat-icon matSuffix class="secondary-text fa-duotone fa-envelope"></mat-icon>
        <!-- <mat-icon matSuffix class="secondary-text" fontSet="fas" fontIcon="fa-envelope"></mat-icon> -->
        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
          <mat-error *ngIf="f['email'].errors['required']">Email is required</mat-error>
          <mat-error *ngIf="f['email'].errors['email']">Email must be a valid email address</mat-error>
        </div>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline">
        <mat-label>Current Password</mat-label>
        <input matInput type="password" formControlName="oldPassword" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f['oldPassword'].errors }" />
        <mat-icon matSuffix class="secondary-text fa-duotone fa-key"></mat-icon>
        <!-- <mat-icon matSuffix class="secondary-text" fontSet="fas" fontIcon="fa-key"></mat-icon> -->
        <div *ngIf="submitted && f['oldPassword'].errors" class="invalid-feedback">
          <mat-error *ngIf="f['oldPassword'].errors['required']">
            Password is required
          </mat-error>
        </div>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline">
        <mat-label>New Password</mat-label>
        <input matInput type="password" formControlName="password" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
        <mat-icon matSuffix class="secondary-text fa-duotone fa-key"></mat-icon>
        <!-- <mat-icon matSuffix class="secondary-text" fontSet="fas" fontIcon="fa-key"></mat-icon> -->
        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
          <mat-error *ngIf="f['password'].errors['required']">
            Password is required
          </mat-error>
        </div>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline">
        <mat-label>Password (Confirm)</mat-label>
        <input matInput type="password" formControlName="passwordConfirm" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f['passwordConfirm'].errors }" />
        <mat-icon matSuffix class="secondary-text fa-duotone fa-key"></mat-icon>
        <!-- <mat-icon matSuffix class="secondary-text" fontSet="fas" fontIcon="fa-key"></mat-icon> -->
        <div *ngIf="submitted && f['passwordConfirm'].errors" class="invalid-feedback">
          <mat-error *ngIf="f['passwordConfirm'].errors['required']">
            Password is required
          </mat-error>
          <mat-error
            *ngIf="(!f['passwordConfirm'].errors['required']) && f['passwordConfirm'].errors['passwordsNotMatching']">
            Passwords must match
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()">Ok</button>
  <!-- <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button> -->
</div>