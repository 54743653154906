<div class="main">
  <!-- HEADER -->
  <mat-toolbar class="text-white bg-zinc-700 h-11">
    <i class="fa-duotone fa-microchip pl-2"></i>
    <span class="text-white pl-2">
      Hardware
    </span>
    <span class="flex-auto"></span>
  </mat-toolbar>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content">
    <div>
      <mat-toolbar class="text-white bg-zinc-500 h-11">
        <button mat-raised-button aria-label="refresh" matTooltip="Refresh" matTooltipPosition="below"
          (click)="reload()">
          <i class="fa-duotone fa-arrows-rotate"></i>
        </button>

        <button mat-raised-button aria-label="add" matTooltip="Add" matTooltipPosition="below" (click)="add()"
          class="ml-2" *ngIf="authenticated">
          <i class="fa-duotone fa-plus"></i>
        </button>

        <span class="flex-auto"></span>

        <button mat-icon-button aria-label="legend" matTooltip="Legend" matTooltipPosition="below" class="mr-2"
          (click)="displayLegend()">
          <i class="fa-duotone fa-bars-staggered"></i>
        </button>

        <button mat-icon-button aria-label="filter" matTooltip="Filters" matTooltipPosition="below"
          [matMenuTriggerFor]="searchMenu" class="mr-2" *ngIf="authenticated">
          <i class="fa-duotone fa-filter-list"></i>
        </button>
      </mat-toolbar>
    </div>

    <mat-menu #searchMenu="matMenu" class="w-36">
      <!-- <div>
        <mat-checkbox class="mat-menu-item" [(ngModel)]="filterValues.original125" (change)="externalFilterChanged()">
          Original 125</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="mat-menu-item" [(ngModel)]="filterValues.homebrew"
          (change)="externalFilterChanged()">Homebrew
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="mat-menu-item" [(ngModel)]="filterValues.variant"
          (change)="externalFilterChanged()">Variants
        </mat-checkbox>
      </div> -->
      <div>
        <mat-checkbox class="mat-menu-item" [(ngModel)]="filterValues.unreleased" (change)="externalFilterChanged()">
          Unreleased</mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="mat-menu-item" [(ngModel)]="filterValues.incomplete" (change)="externalFilterChanged()"
          *ngIf="authenticated">Incomplete</mat-checkbox>
      </div>
      <div>
        <mat-divider *ngIf="authenticated"></mat-divider>
      </div>
      <div>
        <mat-checkbox class="mat-menu-item" [(ngModel)]="filterValues.inventory" (change)="externalFilterChanged()"
          *ngIf="authenticated">Inventory</mat-checkbox>
      </div>
    </mat-menu>

    <div class="mat-elevation-z8 h-full">
      <ag-grid-angular class="ag-theme-alpine-dark h-full w-full" [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef" [rowData]="rowData" [animateRows]="true" [getRowId]="getRowId"
        [getRowClass]="getRowClass" [isExternalFilterPresent]="isExternalFilterPresent"
        [doesExternalFilterPass]="doesExternalFilterPass.bind(this)" (gridReady)="onGridReady($event)"
        [pagination]="true" [paginationAutoPageSize]="true"
        (paginationChanged)="paginationChanged($event)"></ag-grid-angular>
    </div>
  </div>
</div>