import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material/material.module';

import { AgGridModule } from 'ag-grid-angular';

import { NgImageSliderModule } from 'ng-image-slider';
import { NgxMaskPipe, NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { ApiKeyInterceptor } from './_interceptors/api-key-interceptor';
import { TokenInterceptor } from './_interceptors/token-interceptor';

import { NavigationComponent } from './navigation/navigation.component';

import { AddPublisherComponent } from './dialogs/add-publisher/add-publisher.component';
import { SignInDialogComponent } from './dialogs/authentication/sign-in-dialog/sign-in-dialog.component';
import { ChangePasswordDialogComponent } from './dialogs/authentication/change-password-dialog/change-password-dialog.component';
import { DisplayLegendComponent } from './dialogs/display-legend/display-legend.component';

import { HomeComponent } from './home/home.component';
import { HardwareEditorComponent } from './hardware/hardware-editor/hardware-editor.component';
import { HardwareListingComponent } from './hardware/hardware-listing/hardware-listing.component';
import { MiscellaneousListingComponent } from './miscellaneous/miscellaneous-listing/miscellaneous-listing.component';
import { MiscellaneousEditorComponent } from './miscellaneous/miscellaneous-editor/miscellaneous-editor.component';
import { SoftwareEditorComponent } from './software/software-editor/software-editor.component';
import { SoftwareListingComponent } from './software/software-listing/software-listing.component';

import { LinkRendererComponent } from './renderers/link-renderer/link-renderer.component';
import { ScansRendererComponent } from './renderers/scans-renderer/scans-renderer.component';
import { InventoryRendererComponent } from './renderers/inventory-renderer/inventory-renderer.component';
import { ToolsRendererComponent } from './renderers/tools-renderer/tools-renderer.component';
import {
  AuthenticationService,
  ConfigurationService,
  IntvService,
  LoggingService,
} from './services';

@NgModule({
  declarations: [
    AddPublisherComponent,
    AppComponent,
    ChangePasswordDialogComponent,
    HardwareEditorComponent,
    HardwareListingComponent,
    HomeComponent,
    InventoryRendererComponent,
    LinkRendererComponent,
    MiscellaneousEditorComponent,
    MiscellaneousListingComponent,
    NavigationComponent,
    ScansRendererComponent,
    SignInDialogComponent,
    SoftwareEditorComponent,
    SoftwareListingComponent,
    ToolsRendererComponent,
    DisplayLegendComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    FormsModule,
    ReactiveFormsModule,

    MaterialModule,

    AgGridModule,

    NgImageSliderModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    ConfigurationService,
    IntvService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (configurationService: ConfigurationService, intvService: IntvService) => async () => {
          await configurationService.load();
          intvService.loadPublishers();
        },
      deps: [ConfigurationService, IntvService],
      multi: true,
    },
    AuthenticationService,
    LoggingService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
