<div class="h-screen">
  <!-- HEADER -->
  <mat-toolbar class="text-white bg-zinc-700 h-11">
    <i class="fa-duotone fa-microchip pl-2"></i>
    <span class="text-white pl-2">
      {{ title }}
    </span>
  </mat-toolbar>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="h-full bg-zinc-300">
    <form [formGroup]="form" class="bg-zinc-300">
      <input type="hidden" formControlName="id" />
      <div class="grid grid-cols-4 grid-rows-8 gap-1 p-2">
        <div class="col-span-4">
          <mat-form-field class="w-96" appearance="outline">
            <mat-label>Publisher</mat-label>
            <input type="text" placeholder="Pick one" aria-label="Publisher" matInput formControlName="publisher"
              [matAutocomplete]="publisher" required />
            <mat-autocomplete autoActiveFirstOption #publisher="matAutocomplete" [displayWith]="getPublisherName">
              <mat-option *ngFor="let publisher of filteredPublishers | async" [value]="publisher">
                {{ publisher.description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <button mat-button mat-raised-button matTooltipPosition="above" matTooltip="New Publisher" class="ml-2"
            aria-label="Add" (click)="addPublisher()">
            <i class="fa-duotone fa-plus"></i>
          </button>
        </div>

        <div class="col-span-4">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Description</mat-label>
            <input matInput name="description" formControlName="description" required />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Model Number</mat-label>
            <input matInput name="modelNumber" formControlName="modelNumber" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Copyright Year</mat-label>
            <input matInput name="copyrightYear" formControlName="copyrightYear" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Release Year</mat-label>
            <input matInput name="releaseYear" formControlName="releaseYear" />
          </mat-form-field>
        </div>

        <div class="col-span-4 row-span-3">
          <mat-form-field appearance="outline" class="w-full h-full">
            <mat-label>Notes</mat-label>
            <input matInput name="notes" formControlName="notes" />
          </mat-form-field>
        </div>

        <div class="col-span-4">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>History Link</mat-label>
            <input matInput name="historyLink" formControlName="historyLink" />
          </mat-form-field>
        </div>

        <div formGroupName="flags" class="border-solid border-zinc-700 border-2 rounded">
          <div class="p-2">
            <div class="text-base text-black">Flags</div>
            <div>
              <mat-checkbox name="homebrew" formControlName="homebrew">Homebrew</mat-checkbox>
            </div>
            <div>
              <mat-checkbox name="released" formControlName="released">Released</mat-checkbox>
            </div>
            <div>
              <mat-checkbox name="variant" formControlName="variant">Variant</mat-checkbox>
            </div>
          </div>

          <div *ngIf="isVariant()" class="col-span-4">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Original Item</mat-label>
              <input matInput placeholder="Search" aria-label="Original Item" [matAutocomplete]="originalItem"
                formControlName="originalItem" />
              <mat-autocomplete #originalItem="matAutocomplete" [displayWith]="getHardwareName">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let item of filteredHardware" [value]="item">
                    <span>{{ item.publisher.description }} - {{ item.description }} {{ item.notes ? '(' + item.notes +
                      ')' : ''}}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div formGroupName="inventory" class="border-solid border-zinc-700 border-2 rounded">
          <div class="p-2">
            <div class="text-base text-black">Inventory</div>
            <div>
              <mat-checkbox name="component" formControlName="component">Component</mat-checkbox>
            </div>
            <div>
              <mat-checkbox name="manual" formControlName="manual">Manual</mat-checkbox>
            </div>
            <div>
              <mat-checkbox name="box" formControlName="box">Box</mat-checkbox>
            </div>
          </div>
        </div>

      </div>
    </form>

    <div class="p-2">
      <button mat-raised-button [disabled]="form.invalid || form.pristine" (click)="save()">
        SAVE
      </button>

      <button mat-raised-button class="ml-8" (click)="discard()">
        <span *ngIf="form.pristine">Back</span>
        <span *ngIf="!form.pristine">Discard</span>
      </button>
    </div>
  </div>
</div>