import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConsoleLogger, LoggingService } from 'src/app/services';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit {
  private _logger: ConsoleLogger;
  form!: FormGroup;
  hide = true;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,

    private loggingService: LoggingService,

    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { email: string; password: string; newPassword: string; passwordConfirm: string }
  ) {
    this._logger = loggingService.getLogger('ChangePasswordDialogComponent');
    this.createForm();
  }

  ngOnInit(): void {
    // this.createForm();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this._logger.debug('onSubmit', { value: this.form.value });

    // display form values on success
    this.dialogRef.close(this.form.value);
  }

  createForm(): void {
    this.form = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        oldPassword: ['', Validators.required],
        password: ['', Validators.required],
        passwordConfirm: ['', [Validators.required]],
      },
      { validators: this.confirmPasswordValidator }
    );
  }

  confirmPasswordValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.parent || !control) {
      return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
      return null;
    }

    if (passwordConfirm.value === '') {
      return null;
    }

    if (password.value === passwordConfirm.value) {
      return null;
    }

    return { passwordsNotMatching: true };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form?.controls;
  }
}
