import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ICellRendererParams } from 'ag-grid-community';

import { environment } from 'src/environments/environment';

import { ConsoleLogger, IntvService, LoggingService } from 'src/app/services';
import { ICellRendererAngularComp } from 'ag-grid-angular';

interface ToolRendererParams extends ICellRendererParams {
  category: string;
  lastNavigation: string;
  navigation: string;
}

@Component({
  selector: 'app-tools-renderer',
  templateUrl: './tools-renderer.component.html',
  styleUrls: ['./tools-renderer.component.scss'],
})
export class ToolsRendererComponent implements ICellRendererAngularComp {
  private _logger: ConsoleLogger;
  params!: ToolRendererParams;

  constructor(
    private router: Router,
    private loggingService: LoggingService,
    private intvService: IntvService
  ) {
    this._logger = loggingService.getLogger('ToolsRenderer', environment.logLevel);
  }

  // gets called once before the renderer is used
  agInit(params: ToolRendererParams): void {
    this.params = params;
  }

  // gets called whenever the cell refreshes
  refresh(params: ToolRendererParams): boolean {
    this.params = params;
    return true;
  }

  edit() {
    if (this.params) {
      this._logger.debug('edit', {
        params: this.params,
        lastNavigation: this.params.lastNavigation,
        currentPage: this.params.api.paginationGetCurrentPage(),
      });
      this.intvService.lastNavigation$.next({
        route: this.params.lastNavigation,
        page: this.params.api.paginationGetCurrentPage(),
      });
      this.intvService.selectedItem$.next(this.params.data);
      this.router.navigate([this.params.navigation]);
    }
  }

  remove() {
    if (this.params) {
      this._logger.debug('remove', {
        params: this.params,
        item: this.params.data,
        lastNavigation: this.params.lastNavigation,
        currentPage: this.params.api.paginationGetCurrentPage(),
      });
      switch (this.params.category) {
        case 'hardware':
          this._logger.debug('remove.hardware');
          this.intvService.deleteHardwareItem(this.params.data.id).subscribe((x) => {
            this._logger.debug('remove.hardware', { result: x });
            this.params.api.applyTransaction({ remove: [{ id: this.params.node.id }] });
          });
          break;
        case 'miscellaneous':
          this._logger.debug('remove.miscellaneous');
          this.intvService.deleteMiscellaneousItem(this.params.data.id).subscribe((x) => {
            this._logger.debug('remove.miscellaneous', { result: x });
            this.params.api.applyTransaction({ remove: [{ id: this.params.node.id }] });
          });
          break;
        case 'software':
          this._logger.debug('remove.software');
          this.intvService.deleteSoftwareItem(this.params.data.id).subscribe((x) => {
            this._logger.debug('remove.software', { result: x });
            this.params.api.applyTransaction({ remove: [{ id: this.params.node.id }] });
          });
          break;
      }
    }
  }
}
