<mat-nav-list class="h-auto">
  <mat-list-item routerLink="/" [ngClass]="route.value === '' ? 'bg-zinc-600': ''">
    <i class="fa-duotone fa-house text-white"></i><span class="text-white text-base ml-4"
      [ngClass]="route.value === '' ? 'font-bold': ''">Home</span>
  </mat-list-item>
  <mat-list-item routerLink="/hardware" [ngClass]="route.value === 'hardware' ? 'bg-zinc-600': ''">
    <i class="fa-duotone fa-memory text-white"></i><span class="text-white text-base ml-4"
      [ngClass]="route.value === 'hardware' ? 'font-bold': ''">Hardware</span>
  </mat-list-item>
  <mat-expansion-panel class="bg-zinc-900 border-none border-transparent rounded-none shadow-none"
    [expanded]="softwareExpanded()">
    <mat-expansion-panel-header class="text-white text-base pl-4 bg-zinc-900">
      <i class="fa-duotone fa-alien-8bit"></i><span class="ml-4">Software</span>
    </mat-expansion-panel-header>
    <mat-nav-list class="bg-zinc-900">
      <cdk-virtual-scroll-viewport [itemSize]="24" class="h-[32rem]">
        <div *cdkVirtualFor="let publisher of publishers | async">
          <a mat-list-item routerLink="software/{{publisher.id}}" *ngIf="publisher.software"
            [ngClass]="publisher.id === selectedPublisherId.value ? 'bg-zinc-600': ''"><span
              class="text-white text-base"
              [ngClass]="publisher.id === selectedPublisherId.value ? 'font-bold': ''">{{publisher.description}}</span></a>
        </div>
      </cdk-virtual-scroll-viewport>
    </mat-nav-list>
  </mat-expansion-panel>
  <mat-list-item routerLink="/miscellaneous" [ngClass]="route.value === 'miscellaneous' ? 'bg-zinc-600': ''">
    <i class="fa-duotone fa-boxes-stacked text-white"></i><span class="text-white text-base ml-4"
      [ngClass]="route.value === 'miscellaneous' ? 'font-bold': ''">Miscellaneous</span>
  </mat-list-item>
</mat-nav-list>