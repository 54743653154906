/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';

/** Signature of a logging function */
export interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
export interface Logger {
  debug: LogFn;
  warn: LogFn;
  error: LogFn;
}

/** Log levels */
export type LogLevel = 'debug' | 'warn' | 'error' | string;

/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
  private _prefix = '';

  readonly debug: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;

  constructor(options?: { prefix?: string; logLevel?: LogLevel }) {
    const { prefix, logLevel } = options || {};
    this._prefix = `[${prefix}]: ` ?? '';

    this.error = this._prefix
      ? console.error.bind(console, this._prefix)
      : console.error.bind(console);

    if (logLevel === 'error') {
      this.warn = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.warn = this._prefix
      ? console.warn.bind(console, this._prefix)
      : console.warn.bind(console);

    if (logLevel === 'warn') {
      this.debug = NO_OP;

      return;
    }

    this.debug = this._prefix ? console.log.bind(console, this._prefix) : console.log.bind(console);
  }
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  public getLogger(source: string, logLevel: LogLevel = 'debug'): ConsoleLogger {
    return new ConsoleLogger({ prefix: source, logLevel });
  }
}

/*
import 'amazon-connect-streams';

// const isDebug = true;
export class Logger {
  private _logPrefix = '';
  private _logger: connect.Logger;

  constructor(logPrefix?: string) {
    if (logPrefix) {
      this._logPrefix = `[${logPrefix}]: `;
    }
    this._logger = connect.getLog();
  }

  get debug(): Function {
    if (!isDebug) {
      return () => {};
    }
    if (!(window.console && console.error)) {
      return () => {};
    }
    const logPrefix = this.logPrefix;
    if (logPrefix.length) {
      return console.debug.bind(window.console, logPrefix);
    } else {
      return console.debug.bind(window.console);
    }
  }

  get log(): Function {
    if (!isDebug) {
      return () => {};
    }
    if (!(window.console && console.error)) {
      return () => {};
    }
    const logPrefix = this.logPrefix;
    if (logPrefix.length) {
      return console.log.bind(window.console, logPrefix);
    } else {
      return console.log.bind(window.console);
    }
  }

  get warn(): Function {
    if (!isDebug) {
      return () => {};
    }
    if (!(window.console && console.error)) {
      return () => {};
    }
    const logPrefix = this.logPrefix;
    if (logPrefix.length) {
      return console.warn.bind(window.console, logPrefix);
    } else {
      return console.warn.bind(window.console);
    }
  }

  get dir(): Function {
    if (!isDebug) {
      return () => {};
    }
    if (!(window.console && console.error)) {
      return () => {};
    }
    const logPrefix = this.logPrefix;
    if (logPrefix.length) {
      return console.dir.bind(window.console, logPrefix);
    } else {
      return console.dir.bind(window.console);
    }
  }

  get error(): Function {
    if (!isDebug) {
      return () => {};
    }
    if (!(window.console && console.error)) {
      return () => {};
    }
    const logPrefix = this.logPrefix;
    if (logPrefix.length) {
      return console.error.bind(window.console, logPrefix);
    } else {
      return console.error.bind(window.console);
    }
  }
}

const loggers: {[name: string]: Logger} = {};

export const getLogger = (serviceName: string): Logger => {
  if (!loggers[serviceName]) {
    loggers[serviceName] = new Logger(serviceName);
  }
  return loggers[serviceName];
};
*/
// export class Logger {
//   private logTrace: boolean = true;

//   constructor(private name: string) {
//     console.error.bind(window.console);
//   }

//   debug(message: string, ...properties: any[]) {
//     if (this.logTrace) {
//       if (!(window.console && console.debug)) {
//         return;
//       }
//       if (properties.length) {
//         console.debug(`${this.name}: ${message}`, ...properties);
//       } else {
//         console.debug(`${this.name}: ${message}`);
//       }
//     }
//   }

//   error(message: string, ...properties: any[]) {
//     if (!(window.console && console.error)) {
//       return;
//     }
//     if (properties.length) {
//       console.error(`${this.name}: ${message}`, ...properties);
//     } else {
//       console.error(`${this.name}: ${message}`);
//     }
//   }

//   errorObj(error: unknown) {
//     console.error(this.name, JSON.stringify(error, Object.getOwnPropertyNames(error)));
//   }
// }
