import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  CellClassParams,
  ColDef,
  ColumnApi,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  PaginationChangedEvent,
  RowClassParams,
} from 'ag-grid-community';

import { environment } from 'src/environments/environment';

import { Miscellaneous } from 'src/app/models';
import {
  AuthenticationService,
  ConsoleLogger,
  IntvService,
  LoggingService,
} from 'src/app/services';
import { LinkRendererComponent } from 'src/app/renderers/link-renderer/link-renderer.component';
import { ScansRendererComponent } from 'src/app/renderers/scans-renderer/scans-renderer.component';
import { InventoryRendererComponent } from 'src/app/renderers/inventory-renderer/inventory-renderer.component';
import { ToolsRendererComponent } from 'src/app/renderers/tools-renderer/tools-renderer.component';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DisplayLegendComponent } from 'src/app/dialogs/display-legend/display-legend.component';

@Component({
  selector: 'app-miscellaneous-listing',
  templateUrl: './miscellaneous-listing.component.html',
  styleUrls: ['./miscellaneous-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MiscellaneousListingComponent implements OnInit, OnDestroy {
  private _logger: ConsoleLogger;
  private gridApi!: GridApi<Miscellaneous>;
  private gridColumnApi!: ColumnApi;

  private subscriptions: { [key: string]: Subscription } = {};

  authenticated = false;
  filterValues = {
    original125: true,
    homebrew: true,
    variant: true,
    unreleased: true,
    incomplete: false,
    publisher: false,
    inventory: false,
  };

  rowData: Miscellaneous[] = [];
  defaultColDef: ColDef = {
    filter: true,
  };
  columnDefs: ColDef[] = [
    {
      field: 'publisher.description',
      headerName: 'Publisher',
      resizable: true,
      sort: 'asc',
      sortIndex: 0,
      sortable: true,
      cellClass: this.getCellClass.bind(this),
    },
    {
      field: 'description',
      headerName: 'Title',
      resizable: true,
      sort: 'asc',
      sortIndex: 1,
      sortable: true,
      cellClass: this.getCellClass.bind(this),
    },
    {
      field: 'modelNumber',
      headerName: 'Model Number',
      resizable: true,
      sortable: true,
      cellClass: this.getCellClass.bind(this),
    },
    {
      field: 'notes',
      headerName: 'Notes',
      resizable: true,
      filter: false,
      sortable: true,
      cellClass: this.getCellClass.bind(this),
    },
    {
      field: 'copyrightYear',
      headerName: 'Year',
      width: 80,
      sortable: true,
      cellClass: this.getCellClass.bind(this),
    },
    {
      field: 'historyLink',
      headerName: 'History',
      cellRenderer: LinkRendererComponent,
      filter: false,
      sortable: false,
      width: 85,
    },
    {
      field: 's3keys',
      headerName: 'Scans',
      cellRenderer: ScansRendererComponent,
      filter: false,
      sortable: false,
      width: 170,
    },
  ];

  dialogRef!: MatDialogRef<DisplayLegendComponent>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private loggingService: LoggingService,
    private intvService: IntvService
  ) {
    this._logger = loggingService.getLogger('MiscellaneousListing', environment.logLevel);
  }

  ngOnInit(): void {
    this.subscriptions['authenticated'] = this.authenticationService.authenticated$.subscribe(
      (authenticated) => {
        this.authenticated = authenticated;
        if (authenticated) {
          const colDefs = [...this.columnDefs];
          colDefs.push({
            field: 'inventory',
            headerName: 'Inventory',
            cellRenderer: InventoryRendererComponent,
            filter: false,
            sortable: false,
            width: 200,
          });
          colDefs.push({
            field: 'tools',
            headerName: 'Tools',
            cellRenderer: ToolsRendererComponent,
            cellRendererParams: {
              lastNavigation: 'miscellaneous',
              navigation: 'miscellaneous/edit',
            },
            filter: false,
            sortable: false,
            width: 120,
          });
          this.columnDefs = colDefs;
        } else {
          const colDefs = this.columnDefs.filter(
            (x) => x.field && !['inventory', 'tools'].includes(x.field)
          );
          this.columnDefs = colDefs;
        }
      }
    );
  }

  ngOnDestroy(): void {
    // this._logger.debug('ngOnDestroy');
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.unsubscribe();
    }
  }

  add(): void {
    this.intvService.lastNavigation$.next({
      route: 'miscellaneous',
      page: this.gridApi.paginationGetCurrentPage(),
    });
    this.intvService.selectedItem$.next({
      inventory: {
        box: false,
        component: false,
        manual: false,
        overlays: {
          current: 0,
          total: 0,
        },
      },
    } as Partial<Miscellaneous>);
    this.router.navigate(['miscellaneous/add']);
  }

  displayLegend(): void {
    // console.log('addPublisher');
    this.dialogRef = this.matDialog.open(DisplayLegendComponent, {});

    this.subscriptions['afterClosed'] = this.dialogRef.afterClosed().subscribe((response) => {
      this._logger.debug('response', { response });
    });
  }

  doesExternalFilterPass(node: IRowNode<Miscellaneous>): boolean {
    if (node.data) {
      // if (node.data.original125 && !this.filterValues.original125) {
      //   return false;
      // }

      // if (node.data.homebrew && !this.filterValues.homebrew) {
      //   return false;
      // }

      // if (node.data.variant && !this.filterValues.variant) {
      //   return false;
      // }

      // if (!node.data.released && !this.filterValues.unreleased) {
      //   return false;
      // }

      if (this.filterValues.incomplete) {
        return (
          !node.data.inventory.box ||
          !node.data.inventory.component ||
          !node.data.inventory.manual ||
          (node.data.inventory.overlays
            ? node.data.inventory.overlays.current !== node.data.inventory.overlays.total &&
              node.data.inventory.overlays.total > 0
            : false)
        );
      }

      if (this.filterValues.inventory) {
        return (
          node.data.inventory.box || node.data.inventory.component || node.data.inventory.manual
        );
      }
    }
    return true;
  }

  externalFilterChanged() {
    this.gridApi.onFilterChanged();
  }

  isExternalFilterPresent(): boolean {
    return true;
  }

  getCellClass(params: CellClassParams<Miscellaneous>): string | string[] | null | undefined {
    if (
      this.authenticated &&
      params.data &&
      params.data.inventory &&
      params.data.inventory.box &&
      params.data.inventory.manual &&
      params.data.inventory.component
    ) {
      return 'text-green-500';
    }
    if (
      this.authenticated &&
      params.data &&
      params.data.inventory &&
      (params.data.inventory.box || params.data.inventory.manual || params.data.inventory.component)
    ) {
      return 'text-yellow-500';
    }
    return;
  }

  getRowClass(_params: RowClassParams<Miscellaneous>): string | string[] | undefined {
    let result;

    // if (!params.data?.released) {
    //   result = ['italic'];
    // }
    // if (params.data?.released) {
    //   if (!result) {
    //     result = [];
    //   }
    //   if (!params.data?.variant) {
    //     result.push('font-bold');
    //   }
    // }
    return result;
  }

  getRowId(params: GetRowIdParams): string {
    return params.data.id;
  }

  onGridReady(params: GridReadyEvent) {
    // this._logger.debug('onGridReady', { params });
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.reload();
  }

  paginationChanged(_event: PaginationChangedEvent): void {
    // this._logger.debug('paginationChanged', {
    //   event,
    //   pageCount: this.gridApi ? this.gridApi.paginationGetTotalPages() : 0,
    //   page: this.intvService.lastNavigation$.value.page,
    // });

    if (this.gridApi) {
      if (this.intvService.lastNavigation$.value.route === 'hardware') {
        this.gridApi.paginationGoToPage(this.intvService.lastNavigation$.value.page);
      }
    }
  }

  reload(): void {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
      this.subscriptions['get'] = this.intvService
        .getMiscellaneous()
        .subscribe((data: Miscellaneous[]) => {
          // this._logger.debug('reload', { data });
          this.rowData = data;
          this.gridApi.hideOverlay();
        });
    }
  }
}
