import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Publisher } from '../models';
import { ConsoleLogger, LoggingService } from '../services';
import { IntvService } from '../services/intv.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;
  private _logger: ConsoleLogger;
  private subscriptions: { [key: string]: Subscription } = {};

  publishers = new BehaviorSubject<Publisher[]>([]);
  private publisherIndex = 0;
  selectedPublisherId = new BehaviorSubject<string>('');
  route = new BehaviorSubject<string>('');

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loggingService: LoggingService,
    private intvService: IntvService
  ) {
    this._logger = loggingService.getLogger('NavigationComponent', environment.logLevel);
  }

  ngOnInit(): void {
    this.subscriptions['publishers'] = this.intvService.publishers$.subscribe((x) => {
      this.publishers.next(x);
      // this._logger.debug('ngOnInit', { publishers: this.publishers.value });

      this.subscriptions['route'] = this.intvService.currentRoute$.subscribe((x: string[]) => {
        // TODO: fix "lastNavigation"/"currentRoute" in intvService
        this._logger.debug('ngOnInit', {
          val: x,
          lastNavigation: this.intvService.lastNavigation$.value,
          route: this.route.value,
        });

        this.route.next(x[0]);
        if (x[0] === 'software') {
          this.selectedPublisherId.next(this.intvService.currentRoute$.value[1]);
          this.publisherIndex = this.publishers.value.findIndex(
            (pub) => pub.id === this.selectedPublisherId.value
          );

          // this._logger.debug('ngOnInit', { publisherIndex: this.publisherIndex });
          if (this.viewport) {
            // this._logger.debug('ngOnInit', {
            //   viewport: this.viewport,
            //   publisherIndex: this.publisherIndex,
            // });
            setTimeout(() => {
              this.viewport.scrollToIndex(this.publisherIndex, 'auto');
            }, 1000);
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.unsubscribe();
    }
  }

  softwareExpanded(): boolean {
    return this.publishers.value.length > 0;
  }
}
