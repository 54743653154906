import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

import { environment } from 'src/environments/environment';

import { Inventory } from 'src/app/models';
import { ConsoleLogger, IntvService, LoggingService } from 'src/app/services';

@Component({
  selector: 'app-inventory-renderer',
  templateUrl: './inventory-renderer.component.html',
  styleUrls: ['./inventory-renderer.component.scss'],
})
export class InventoryRendererComponent {
  private _logger: ConsoleLogger;
  private params!: ICellRendererParams;
  public cellValue: Inventory = {} as Inventory;

  constructor(private loggingService: LoggingService, private intvService: IntvService) {
    this._logger = loggingService.getLogger('InventoryRenderer', environment.logLevel);
  }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellValue = this.getValueToDisplay(params);
  }

  getValueToDisplay(params: ICellRendererParams) {
    const result: Inventory = params.value;

    if (!result.overlays) {
      result.overlays = {
        current: 0,
        total: 0,
      };
    }
    return result;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.cellValue = this.getValueToDisplay(params);
    this._logger.debug('refresh', { params });
    return true;
  }
}
