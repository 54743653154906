import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';

import { HardwareEditorComponent } from './hardware/hardware-editor/hardware-editor.component';
import { HardwareListingComponent } from './hardware/hardware-listing/hardware-listing.component';
import { HomeComponent } from './home/home.component';
import { MiscellaneousEditorComponent } from './miscellaneous/miscellaneous-editor/miscellaneous-editor.component';
import { MiscellaneousListingComponent } from './miscellaneous/miscellaneous-listing/miscellaneous-listing.component';
import { SoftwareEditorComponent } from './software/software-editor/software-editor.component';
import { SoftwareListingComponent } from './software/software-listing/software-listing.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'hardware',
    component: HardwareListingComponent,
  },
  {
    path: 'hardware/add',
    component: HardwareEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hardware/edit',
    component: HardwareEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'miscellaneous',
    component: MiscellaneousListingComponent,
  },
  {
    path: 'miscellaneous/add',
    component: MiscellaneousEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'miscellaneous/edit',
    component: MiscellaneousEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'software/add',
    component: SoftwareEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'software/edit',
    component: SoftwareEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'software/:publisher',
    component: SoftwareListingComponent,
  },

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
    //    component: HomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
