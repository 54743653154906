import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-display-legend',
  templateUrl: './display-legend.component.html',
  styleUrls: ['./display-legend.component.scss'],
})
export class DisplayLegendComponent {
  constructor(public dialogRef: MatDialogRef<DisplayLegendComponent>) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
