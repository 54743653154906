import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  CellClassParams,
  ColDef,
  ColumnApi,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  PaginationChangedEvent,
  RowClassParams,
  ValueFormatterParams,
} from 'ag-grid-community';

import { environment } from 'src/environments/environment';

import { Publisher, Software } from 'src/app/models';
import {
  AuthenticationService,
  ConsoleLogger,
  IntvService,
  LoggingService,
} from 'src/app/services';
import { LinkRendererComponent } from 'src/app/renderers/link-renderer/link-renderer.component';
import { ScansRendererComponent } from 'src/app/renderers/scans-renderer/scans-renderer.component';
import { InventoryRendererComponent } from 'src/app/renderers/inventory-renderer/inventory-renderer.component';
import { ToolsRendererComponent } from 'src/app/renderers/tools-renderer/tools-renderer.component';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DisplayLegendComponent } from 'src/app/dialogs/display-legend/display-legend.component';

@Component({
  selector: 'app-software-listing',
  templateUrl: './software-listing.component.html',
  styleUrls: ['./software-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SoftwareListingComponent implements OnInit, OnDestroy {
  private _logger: ConsoleLogger;
  private gridApi!: GridApi<Software>;
  private gridColumnApi!: ColumnApi;

  private subscriptions: { [key: string]: Subscription } = {};

  authenticated = false;
  filterValues = {
    original125: true,
    homebrew: true,
    variant: true,
    unreleased: true,
    incomplete: false,
    publisher: false,
    inventory: false,
  };

  publisherId = '0';
  publisher: Publisher = {
    id: '0',
    description: '[All]',
    software: true,
  } as Publisher;

  rowData: Software[] = [];
  defaultColDef: ColDef = {
    filter: true,
    resizable: true,
    sortable: true,
  };
  columnDefs: ColDef[] = [
    {
      field: 'publisher.description',
      headerName: 'Publisher',
      sort: 'asc',
      cellClass: this.getCellClass.bind(this),
      minWidth: 20,
    },
    {
      field: 'description',
      headerName: 'Title',
      // flex: 1,
      sort: 'asc',
      cellClass: this.getCellClass.bind(this),
      minWidth: 30,
    },
    {
      field: 'modelNumber',
      headerName: 'Model Number',
      width: 160,
      cellClass: this.getCellClass.bind(this),
      minWidth: 20,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      // flex: 2,
      cellClass: this.getCellClass.bind(this),
      minWidth: 20,
    },
    {
      field: 'copyrightYear',
      headerName: 'Year',
      width: 80,
      cellClass: this.getCellClass.bind(this),
    },
    {
      field: 'players',
      headerName: 'Players',
      width: 80,
      cellClass: this.getCellClass.bind(this),
      valueFormatter: (params: ValueFormatterParams<Software>) => {
        try {
          // this._logger.debug('valueFormatter', { params });
          return params.value
            ? `${Number(params.value.min) ?? ''} - ${Number(params.value.max) ?? ''}`
            : '';
        } catch (err) {
          this._logger.error('Players error', { value: params.value });
          return '';
        }
      },
    },
    {
      field: 'programmer',
      headerName: 'Programmer',
      width: 120,
      cellClass: this.getCellClass.bind(this),
    },
    {
      field: 'historyLink',
      headerName: 'History',
      cellRenderer: LinkRendererComponent,
      filter: false,
      resizable: false,
      sortable: false,
      width: 85,
    },
    {
      field: 's3keys',
      headerName: 'Scans',
      cellRenderer: ScansRendererComponent,
      filter: false,
      resizable: false,
      sortable: false,
      width: 170,
    },
  ];

  dialogRef!: MatDialogRef<DisplayLegendComponent>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private loggingService: LoggingService,
    private intvService: IntvService
  ) {
    this._logger = loggingService.getLogger('SoftwareListing', environment.logLevel);
  }

  ngOnInit(): void {
    this.subscriptions['route'] = this.route.paramMap.subscribe((map: ParamMap) => {
      // this._logger.debug('route.paramMap', { map, state: window.history.state });

      const id = map.get('publisher');
      if (id) {
        this.publisherId = id;
        // this.publisher.next(this.intvService.publishers$.value.find((x) => x.id === this.publisherId)};
        this.subscriptions['publisher'] = this.intvService.publishers$.subscribe((data) => {
          const publisher = data.find((x) => x.id === this.publisherId);
          if (publisher) {
            this.publisher = publisher;
          }
        });

        if (this.publisher.id !== '0') {
          if (this.gridColumnApi) {
            // Hide publisher column:
            this.gridColumnApi.setColumnsVisible(['publisher.description'], false);
            this.gridColumnApi.applyColumnState({
              state: [{ colId: 'description', sort: 'asc', sortIndex: 1 }],
              defaultState: { sort: null },
            });
          }
        } else {
          if (this.gridColumnApi) {
            this.gridColumnApi.setColumnsVisible(
              ['publisher.description', 'players', 'programmer'],
              true
            );
            this.gridColumnApi.applyColumnState({
              state: [
                { colId: 'publisher.description', sort: 'asc', sortIndex: 0 },
                { colId: 'description', sort: 'asc', sortIndex: 1 },
              ],
              defaultState: { sort: null },
            });
          }
        }

        this.reload();
      }
    });

    this.subscriptions['authenticated'] = this.authenticationService.authenticated$.subscribe(
      (authenticated) => {
        this.authenticated = authenticated;
        if (authenticated) {
          const colDefs = [...this.columnDefs];
          colDefs.push({
            field: 'inventory',
            headerName: 'Inventory',
            cellRenderer: InventoryRendererComponent,
            filter: false,
            resizable: false,
            sortable: false,
            width: 240,
          });
          colDefs.push({
            field: 'tools',
            headerName: 'Tools',
            cellRenderer: ToolsRendererComponent,
            cellRendererParams: {
              category: 'software',
              lastNavigation: `software/${this.publisherId}`,
              navigation: 'software/edit',
            },
            filter: false,
            resizable: false,
            sortable: false,
            width: 120,
          });
          this.columnDefs = colDefs;
        } else {
          const colDefs = this.columnDefs.filter(
            (x) => x.field && !['inventory', 'tools'].includes(x.field)
          );
          this.columnDefs = colDefs;
        }
      }
    );
  }

  ngOnDestroy(): void {
    // this._logger.debug('ngOnDestroy');
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.unsubscribe();
    }
  }

  add(): void {
    this._logger.debug('add', { currentPage: this.gridApi.paginationGetCurrentPage() });
    this.intvService.lastNavigation$.next({
      route: `software/${this.publisherId}`,
      page: this.gridApi.paginationGetCurrentPage(),
    });
    this.intvService.selectedItem$.next({
      publisher: this.publisher,
      inventory: {
        box: false,
        component: false,
        manual: false,
        overlays: {
          current: 0,
          total: 0,
        },
      },
      s3keys: {
        box: '',
        manual: '',
        overlay: '',
      },
    } as Partial<Software>);
    this._logger.debug('add', { item: this.intvService.selectedItem$.value });
    this.router.navigate(['software/edit']);
  }

  displayLegend(): void {
    // console.log('addPublisher');
    this.dialogRef = this.matDialog.open(DisplayLegendComponent, {});

    this.subscriptions['afterClosed'] = this.dialogRef.afterClosed().subscribe((response) => {
      this._logger.debug('response', { response });
    });
  }

  doesExternalFilterPass(node: IRowNode<Software>): boolean {
    if (node.data) {
      if (node.data.original125 && !this.filterValues.original125) {
        return false;
      }

      if (node.data.homebrew && !this.filterValues.homebrew) {
        return false;
      }

      if (node.data.variant && !this.filterValues.variant) {
        return false;
      }

      if (!node.data.released && !this.filterValues.unreleased) {
        return false;
      }

      if (this.filterValues.incomplete) {
        return (
          !node.data.inventory.box ||
          !node.data.inventory.component ||
          !node.data.inventory.manual ||
          (node.data.inventory.overlays
            ? node.data.inventory.overlays.current !== node.data.inventory.overlays.total &&
              node.data.inventory.overlays.total > 0
            : false)
        );
      }

      if (this.filterValues.inventory) {
        return (
          node.data.inventory.box || node.data.inventory.component || node.data.inventory.manual
        );
      }
    }
    return true;
  }

  externalFilterChanged() {
    this.gridApi.onFilterChanged();
  }

  isExternalFilterPresent(): boolean {
    return true;
  }

  getCellClass(params: CellClassParams<Software>): string | string[] | null | undefined {
    if (
      this.authenticated &&
      params.data &&
      params.data.inventory &&
      params.data.inventory.box &&
      params.data.inventory.manual &&
      params.data.inventory.component
    ) {
      return 'text-green-500';
    }
    if (
      this.authenticated &&
      params.data &&
      params.data.inventory &&
      (params.data.inventory.box || params.data.inventory.manual || params.data.inventory.component)
    ) {
      return 'text-yellow-500';
    }
    return;
  }

  getRowClass(params: RowClassParams<Software>): string | string[] | undefined {
    let result;

    if (!params.data?.released) {
      result = ['italic'];
    }
    if (params.data?.released) {
      if (!result) {
        result = [];
      }
      if (!params.data?.variant) {
        result.push('font-bold');
      }
    }
    return result;
  }

  getRowId(params: GetRowIdParams): string {
    return params.data.id;
  }

  onGridReady(params: GridReadyEvent<Software>) {
    // this._logger.debug('onGridReady', { params });
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.reload();
  }

  paginationChanged(_event: PaginationChangedEvent): void {
    this._logger.debug('paginationChanged', {
      lastNavigation: this.intvService.lastNavigation$.value,
      thisRoute: `software/${this.publisherId}`,
      // event,
      pageCount: this.gridApi ? this.gridApi.paginationGetTotalPages() : 0,
      page: this.intvService.lastNavigation$.value.page,
    });

    // if (this.gridApi) {
    //   if (this.intvService.lastNavigation$.value.route === `software/${this.publisherId}`) {
    //     this.gridApi.paginationGoToPage(this.intvService.lastNavigation$.value.page);
    //   }
    // }
  }

  reload(): void {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
      this.subscriptions['get'] = this.intvService
        .getSoftware(this.publisherId)
        .subscribe((data: Software[]) => {
          // this._logger.debug('reload', { data });
          this.rowData = data;
          this.gridApi.hideOverlay();
        });
    }
  }
}
