import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { S3Keys } from 'src/app/models';
import { IntvService } from 'src/app/services/intv.service';

@Component({
  selector: 'scans-renderer',
  templateUrl: './scans-renderer.component.html',
  styleUrls: ['./scans-renderer.component.scss'],
})
export class ScansRendererComponent implements ICellRendererAngularComp {
  private params!: ICellRendererParams;
  public cellValue: S3Keys = {};

  constructor(private intvService: IntvService) {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellValue = params.value;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.cellValue = params.value;
    return true;
  }

  openLink(s3key: string | undefined) {
    if (s3key) {
      this.intvService.getUrl(`${s3key}`).subscribe((url) => {
        window.open(url);
        // window.open(decodeURIComponent(url));
      });
    }
  }
}
