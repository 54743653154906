<h1 mat-dialog-title>Legend</h1>

<div mat-dialog-content>
  <div class="text-green-500">Complete</div>
  <div class="text-yellow-500">Incomplete</div>
  <div class="italic">Not Released</div>
  <div class="font-bold">Original Item</div>
  <div>Variant</div>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onClose()">Ok</button>
</div>