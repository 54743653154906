<h1 mat-dialog-title>Sign In</h1>
<div mat-dialog-content>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput [(ngModel)]="data.email">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput [(ngModel)]="data.password" [type]="hide ? 'password' : 'text'">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon [fontIcon]="hide ? 'visibility_off' : 'visibility'"></mat-icon>
      </button>
    </mat-form-field>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>