import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// import { ConsoleLogger, LoggingService } from './logging.service';

export interface IApiConfig {
  baseURL: string;
  apiKey: string;
}

export interface IConfig {
  api: IApiConfig;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService implements IConfig, OnDestroy {
  private httpClient: HttpClient;
  private subscriptions: { [key: string]: Subscription } = {};
  private _cfg: IConfig = {} as IConfig;
  // private _logger: ConsoleLogger;

  constructor(private handler: HttpBackend /*, private loggingService: LoggingService*/) {
    this.httpClient = new HttpClient(handler);
    // this._logger = loggingService.getLogger('ConfigurationService', 'debug');
  }

  ngOnDestroy(): void {
    // this._logger.debug('ngOnDestroy');
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.unsubscribe();
    }
  }

  public async load(): Promise<void> {
    return new Promise((resolve) => {
      // console.log('load');
      this.httpClient.get('/assets/config.json').subscribe((data) => {
        // console.log('api', data);
        this._cfg.api = data as IApiConfig;
        resolve();
      });
    });

    // if (!this._cfg.api) {
    //   // this._logger.debug('loading...');
    //   this.subscriptions['load'] = this.httpClient.get('assets/config.json').subscribe((data) => {
    //     // this._logger.debug('data', { data });
    //     this._cfg.api = data as IApiConfig;
    //     // this._logger.debug('Config Loaded');
    //   });
    // }
  }

  get api(): IApiConfig {
    return this._cfg.api;
  }
}

/*
import { BehaviorSubject } from 'rxjs';

interface CognitoConfig {
  aws_project_region: string;
  aws_cognito_region: string;

  aws_cognito_identity_pool_id: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;

  oauth: object;
}

interface ServicesConfig {
  [key: string]: {
    url: string;
    apiKey: string;
  };
}

@Injectable()
export class ConfigurationService {
  services: ServicesConfig = {};

  cognito$ = new BehaviorSubject<CognitoConfig>({} as CognitoConfig);

  constructor(private httpClient: HttpClient) {
    // console.log('configurationService.constructor');
  }

  async loadConfiguration(): Promise<void> {
    // console.log('configurationService.loadConfiguration');
    await this.getCognitoConfig();
    await this.getServicesConfig();
  }

  private async getCognitoConfig(): Promise<void> {
    // console.log('configurationService.getCognitoConfig');
    return new Promise((resolve) => {
      this.httpClient.get('/assets/aws-exports.json').subscribe((data) => {
        this.cognito$.next(data as CognitoConfig);
        resolve();
      });
    });
  }

  private async getServicesConfig(): Promise<void> {
    // console.log('configurationService.getServicesConfig');
    this.services = (await this.httpClient
      .get('/assets/services.json')
      .toPromise()) as ServicesConfig;
    // console.log('configurationService.services', this.services);
  }
}
*/
