import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  AuthenticationService,
  ConfigurationService,
  ConsoleLogger,
  LoggingService,
} from '../services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private _logger: ConsoleLogger;

  constructor(
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService,
    private loggingService: LoggingService
  ) {
    this._logger = loggingService.getLogger('TokenInterceptor', environment.logLevel);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this._logger.debug('tokenInterceptor.intercept', { url: req.url });
    const token = this.authenticationService.getToken('idToken');
    if (token) {
      const newRequest = req.clone({
        headers: req.headers.set('Authorization', token),
      });
      return next.handle(newRequest);
    } else {
      return next.handle(req);
    }
  }
}
