import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Hardware, Item, Miscellaneous, Publisher, SearchOptions, Software } from '../models';
import { ConfigurationService } from './configuration.service';
import { ConsoleLogger, LoggingService } from './logging.service';

export interface LastNavigation {
  route: string;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class IntvService implements OnDestroy {
  private _logger: ConsoleLogger;
  private subscriptions: { [key: string]: Subscription } = {};

  searchFilter = {
    description: '',
    original125: true,
    homebrew: true,
    variant: true,
    unreleased: true,
    incomplete: false,
    publisher: false,
    inventory: false,
  };
  publishers$ = new BehaviorSubject<Publisher[]>([]);

  selectedItem$ = new BehaviorSubject<Partial<Hardware | Miscellaneous | Software>>({} as Item);
  lastNavigation$ = new BehaviorSubject<LastNavigation>({ route: '', page: 0 });
  currentRoute$ = new BehaviorSubject<string[]>([]);

  constructor(
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private configurationService: ConfigurationService
  ) {
    this._logger = loggingService.getLogger('IntvService', environment.logLevel);
    // this.loadPublishers();
  }

  ngOnDestroy(): void {
    this._logger.debug('ngOnDestroy');
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.unsubscribe();
    }
  }

  //=========================
  public getHardware(): Observable<Hardware[]> {
    return this.httpClient.get<Hardware[]>(`${this.configurationService.api.baseURL}/hardware`);
  }

  public addHardwareItem(data: Hardware): Observable<Hardware> {
    return this.httpClient.post<Hardware>(
      `${this.configurationService.api.baseURL}/hardware/item`,
      data
    );
  }

  public deleteHardwareItem(id: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${this.configurationService.api.baseURL}/hardware/item/${id}`
    );
  }

  public updateHardwareItem(id: string, data: Hardware): Observable<boolean> {
    return this.httpClient.patch<boolean>(
      `${this.configurationService.api.baseURL}/hardware/item/${id}`,
      data
    );
  }
  //=========================

  //=========================
  public getMiscellaneous(): Observable<Miscellaneous[]> {
    return this.httpClient.get<Miscellaneous[]>(
      `${this.configurationService.api.baseURL}/miscellaneous`
    );
  }

  public addMiscellaneousItem(data: Miscellaneous): Observable<Miscellaneous> {
    return this.httpClient.post<Miscellaneous>(
      `${this.configurationService.api.baseURL}/miscellaneous/item`,
      data
    );
  }

  public deleteMiscellaneousItem(id: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${this.configurationService.api.baseURL}/miscellaneous/item/${id}`
    );
  }

  public updateMiscellaneousItem(id: string, data: Miscellaneous): Observable<boolean> {
    return this.httpClient.patch<boolean>(
      `${this.configurationService.api.baseURL}/miscellaneous/item/${id}`,
      data
    );
  }
  //=========================

  //=========================
  public getSoftware(publisherId: string): Observable<Software[]> {
    return this.httpClient.get<Software[]>(
      `${this.configurationService.api.baseURL}/software/${publisherId}`
    );
  }

  public addSoftwareItem(data: Software): Observable<Software> {
    return this.httpClient.post<Software>(
      `${this.configurationService.api.baseURL}/software/item`,
      data
    );
  }

  public deleteSoftwareItem(id: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${this.configurationService.api.baseURL}/software/item/${id}`
    );
  }

  public getSoftwareItem(id: string): Observable<Software> {
    return this.httpClient.get<Software>(
      `${this.configurationService.api.baseURL}/software/item/${id}`
    );
  }

  public updateSoftwareItem(id: string, data: Software): Observable<boolean> {
    return this.httpClient.patch<boolean>(
      `${this.configurationService.api.baseURL}/software/item/${id}`,
      data
    );
  }
  //=========================

  //=========================
  public addPublisher(data: Partial<Publisher>): Observable<Publisher> {
    return this.httpClient.post<Publisher>(
      `${this.configurationService.api.baseURL}/publisher`,
      data
    );
  }

  public getImageUrls(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${this.configurationService.api.baseURL}/image-urls?key=boxes`
    );
  }

  public getUrl(s3key: string): Observable<string> {
    return this.httpClient.get<string>(
      `${this.configurationService.api.baseURL}/url?key=${encodeURIComponent(s3key)}`
    );
  }

  public search(options: SearchOptions): Observable<Item[]> {
    return this.httpClient.post<Item[]>(`${this.configurationService.api.baseURL}/search`, options);
  }

  public loadPublishers(): void {
    this.subscriptions['load'] = this.httpClient
      .get<Publisher[]>(`${this.configurationService.api.baseURL}/publisher`)
      .subscribe((data) => {
        this.publishers$.next([
          {
            id: '0',
            description: '[All]',
            hardware: false,
            miscellaneous: false,
            software: true,
          } as Publisher,
          ...(data as Publisher[]),
        ]);
        // this._logger.debug('loadPublishers', { publishers: this.publishers$.value });
      });
  }
}
