import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { ConsoleLogger, LoggingService } from '../services/logging.service';
import { ConfigurationService } from '../services';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  private _logger: ConsoleLogger;

  constructor(
    private loggingService: LoggingService,
    private configurationService: ConfigurationService
  ) {
    this._logger = loggingService.getLogger('ApiKeyInterceptor', environment.logLevel);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const apiKey = this.getApiKey(req.url);

    // Clone the request and replace the original headers with
    // cloned headers, updated with the api key
    if (apiKey) {
      const apiReq = req.clone({
        headers: req.headers.set('x-api-key', apiKey),
      });

      // send cloned request with header to the next handler.
      return next.handle(apiReq);
    } else {
      return next.handle(req);
    }
  }

  getApiKey(url: string): string {
    if (this.configurationService.api) {
      const regex = new RegExp(`^${this.configurationService.api.baseURL}`, 'i');
      // const regex = new RegExp(`^${environment.baseURL}`, 'i');
      if (regex.test(url)) {
        return this.configurationService.api.apiKey;
      }
    }
    return '';
  }
}
